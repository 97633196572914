import {useState, useCallback} from 'react';
import logo from './logo.svg';
import {Button} from '@verily-src/react-design-system';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

type ItemListProperties = {
  items: string[];
  remove: (index: number) => void;
};

function ItemList(properties: ItemListProperties) {
  const {items, remove} = properties;
  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={index}
          secondaryAction={
            <IconButton edge="end" role="delete" onClick={() => remove(index)}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText>{item}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

type AddItemFormProperties = {
  addItem: (item: string) => void;
};

function AddItemForm(properties: AddItemFormProperties) {
  const {addItem} = properties;

  const [item, setItem] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    addItem(item);
    setItem('');
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setItem(event.target.value);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack direction="row" spacing={1}>
        <TextField
          size="small"
          label="New item"
          id="item"
          onChange={handleChange}
          value={item}
        />
        <Button type="submit" role="add" disabled={item === ''}>
          Add
        </Button>
      </Stack>
    </Box>
  );
}

function TaskListApplication() {
  const [items, setItems] = useState<string[]>([]);

  const addItem = useCallback(
    (item: string) => {
      setItems([...items, item]);
    },
    [items, setItems]
  );

  const removeItem = useCallback(
    (index: number) => {
      setItems([...items.slice(0, index), ...items.slice(index + 1)]);
    },
    [items, setItems]
  );

  return (
    <>
      <Container maxWidth="xs">
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" spacing={1}>
              <img src={logo} />
              <Typography>SB Testing FE</Typography>
            </Stack>
          </Toolbar>
        </AppBar>
        <ItemList items={items} remove={removeItem} />
        <AddItemForm addItem={addItem} />
      </Container>
    </>
  );
}

export default TaskListApplication;
